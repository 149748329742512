<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model layout="inline">
				<a-form-model-item label="产品名称">
					<a-select style="width: 300px" :value="query.productId" placeholder="请选择产品" @change="onChange">
						<!-- <a-select-option value="">全部</a-select-option> -->
						<a-select-option v-for="item in goodsOptions" :key="item.id" :value="item.id">
							<a-space>
								<a-avatar shape="square" :size="24" :src="item.banner"></a-avatar>
								{{ item.productName }}
							</a-space>
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<!-- <a-form-model-item><a-button type="primary" @click="getConfig" style="margin-left: 10px;">查询</a-button></a-form-model-item> -->
			</a-form-model>
		</a-card>
		<a-card title="系统配置">
			<a-tabs :activeKey="query.roleId" @change="onTabChange">
				<a-tab-pane :key="item.key" :tab="item.text" v-for="item in tabs">
					<a-space direction="vertical" size="middle">
						<a-row :gutter="10" v-for="item in lists[item.key] || []" :key="item.shortName">
							<a-col :span="4">
								<div style="text-align: right;text-transform: uppercase;">{{ item.name }}:</div>
							</a-col>
							<a-col :span="8"><a-input v-model="item.content" /></a-col>
							<a-col :span="4"><a-button type="primary" @click="onSave(item)" :disabled="item.origin == item.content">保存</a-button></a-col>
						</a-row>
					</a-space>
				</a-tab-pane>
				<a-tab-pane key="tighten_3" tab="东方㿟紧缩">
					<a-row type="flex">
						<a-col flex="1">
							<a-form-model layout="inline">
								<a-form-model-item label="用户ID"><a-input v-model="tighten.query.userId" placeholder="用户ID"></a-input></a-form-model-item>
								<a-form-model-item label="手机号码"><a-input v-model="tighten.query.mobile" placeholder="手机号码"></a-input></a-form-model-item>
								<a-form-model-item><a-button type="primary" @click="onSearch">搜索</a-button></a-form-model-item>
							</a-form-model>
						</a-col>
						<a-col><a-button type="primary" @click="onCreateTighten(3)">新增</a-button></a-col>
					</a-row>
					<a-table rowKey="userId" :data-source="tighten.data" :pagination="{ current: tighten.current_page, pageSize: tighten.per_page, total: tighten.total }" @change="onPageChange" :scroll="{ x: 'max-content' }" style="margin-top:8px">
						<a-table-column key="userId" title="用户ID" data-index="userId" />
						<a-table-column key="nickname" title="姓名" data-index="nickname" />
						<a-table-column key="mobile" title="手机号码" data-index="mobile" />
						<a-table-column key="roleIdStr" title="级别" data-index="roleIdStr" />
						<a-table-column key="action" title="操作" fixed="right">
							<template slot-scope="text, record">
								<a-space>
									<a-popconfirm title="确认要删除吗？" @confirm="onDeleteTighten(record)"><a-button type="danger">删除</a-button></a-popconfirm>
								</a-space>
							</template>
						</a-table-column>
					</a-table>
				</a-tab-pane>
				<a-tab-pane key="tighten_2" tab="乐康百年紧缩">
					<a-row type="flex">
						<a-col flex="1">
							<a-form-model layout="inline">
								<a-form-model-item label="用户ID"><a-input v-model="tighten.query.userId" placeholder="用户ID"></a-input></a-form-model-item>
								<a-form-model-item label="手机号码"><a-input v-model="tighten.query.mobile" placeholder="手机号码"></a-input></a-form-model-item>
								<a-form-model-item><a-button type="primary" @click="onSearch">搜索</a-button></a-form-model-item>
							</a-form-model>
						</a-col>
						<a-col><a-button type="primary" @click="onCreateTighten(3)">新增</a-button></a-col>
					</a-row>
					<a-table rowKey="userId" :data-source="tighten.data" :pagination="{ current: tighten.current_page, pageSize: tighten.per_page, total: tighten.total }" @change="onPageChange" :scroll="{ x: 'max-content' }" style="margin-top:8px">
						<a-table-column key="userId" title="用户ID" data-index="userId" />
						<a-table-column key="nickname" title="姓名" data-index="nickname" />
						<a-table-column key="mobile" title="手机号码" data-index="mobile" />
						<a-table-column key="roleIdStr" title="级别" data-index="roleIdStr" />
						<a-table-column key="action" title="操作" fixed="right">
							<template slot-scope="text, record">
								<a-space>
									<a-popconfirm title="确认要删除吗？" @confirm="onDeleteTighten(record)"><a-button type="danger">删除</a-button></a-popconfirm>
								</a-space>
							</template>
						</a-table-column>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-card>

		<a-modal title="新增" v-model="modal.visible" :bodyStyle="{ padding: '10px' }">
			<a-form-model ref="form" :model="modal.formdata" :rules="modal.rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
				<a-form-model-item label="手机号码" prop="mobile"><a-input v-model="modal.formdata.mobile" placeholder="请输入手机号码" :max-length="11" /></a-form-model-item>
			</a-form-model>
			<div slot="footer">
				<a-button @click="modal.visible = false">取消</a-button>
				<a-button type="primary" :loading="modal.loading" @click="onSubmitTighten">保存</a-button>
			</div>
		</a-modal>
	</a-space>
</template>

<script>
	import moment from 'moment';
	import { ROLES } from '@/utils/constants.js';
	import WsUpload from '@/components/WsUpload.vue';

	export default {
		components: {
			WsUpload
		},
		data() {
			return {
				tabKey: 0,
				ROLES,

				query: {
					roleId: undefined,
					productId: undefined
				},
				lists: {},

				goodsOptions: [],

				layout: {
					labelCol: {
						span: 4
					},
					wrapperCol: {
						span: 14
					}
				},

				formdata: {
					wx_qrcode: '',
					zfb_qrcode: ''
				},
				rules: {
					wx_qrcode: [{
						required: true,
						message: '请上传微信收款码',
						trigger: 'change'
					}],
					zfb_qrcode: [{
						required: true,
						message: '请上传支付宝收款码',
						trigger: 'change'
					}]
				},
				qrcodes: [],

				tighten: {
					query: { page: 1, type: undefined, userId: undefined, mobile: undefined },
					data: [],
					total: 0,
					current_page: 1
				},
				modal: {
					visible: false,
					loading: false,
					formdata: { mobile: '' },
					rules: { mobile: { required: true, message: '请输入手机号码', pattern: /^1\d{10}$/ } }
				}
			};
		},
		computed: {
			tabs() {
				const tabs = [];
				Object.keys(ROLES).forEach(key => {
					key > 0 &&
						tabs.push({
							key: +key,
							text: ROLES[key]
						});
				});
				return tabs;
			},
			tabContent() {
				return this.lists[this.tabKey] || [];
			}
		},
		async mounted() {
			const response = await this.$api.get('/user_query_product/4');
			this.goodsOptions = (response && response.data) || [];
			if (this.goodsOptions.length) {
				this.query.productId = this.goodsOptions[0].id;
			}
			this.onTabChange(10);
		},
		methods: {
			async onTabChange(tabKey) {
				this.query.roleId = tabKey;
				if (tabKey == 'tighten_2') {
					this.getTightens({ page: 1, type: 2, userId: undefined, mobile: undefined });
				} else if (tabKey == 'tighten_3') {
					this.getTightens({ page: 1, type: 3, userId: undefined, mobile: undefined });
				} else {
					this.getConfig();
				}
			},
			async onChange(productId) {
				this.query.productId = productId;
				this.getConfig();
			},
			async getConfig() {
				const response = await this.$api.get(`/config_agent_list`, { params: this.query });
				if (response && response.code == 200) {
					response.data = (response.data || []).map(item => {
						item.origin = item.content;
						return item;
					});
					this.$set(this.lists, this.query.roleId, response.data);
				}
			},
			async getTightens(data) {
				Object.assign(this.tighten.query, data);
				const response = await this.$api.get(`/config_tighten_list`, { params: this.tighten.query });
				if (response && response.code == 200) {
					this.tighten.query.page = response.data.current_page;
					Object.assign(this.tighten, response.data);
				}
			},
			async onSearch() {
				this.getTightens({ page: 1 });
			},
			async onPageChange(pagination) {
				this.getTightens({ page: pagination.current });
			},
			async onDeleteTighten(record) {
				const response = await this.$api.post(`/config_tighten_del`, { userId: record.userId, type: this.tighten.query.type });
				if (response && response.code == 200) {
					this.getTightens();
				}
			},
			async onCreateTighten() {
				this.modal.formdata.mobile = '';
				this.modal.loading = false;
				this.modal.visible = true;
			},
			async onSubmitTighten() {
				this.$refs.form.validate(async valid => {
					if (valid) {
						this.modal.loading = true;
						this.modal.formdata.type = this.tighten.query.type;
						const response = await this.$api.post(`/config_tighten_insert`, this.modal.formdata).catch(() => {
							this.modal.loading = false;
						});
						if (response && response.code == 200) {
							this.$message.success('保存成功!');
							this.getTightens();
							this.modal.visible = false;
						}
						this.modal.loading = false;
					}
				});
			},
			async onSave(item) {
				const content = item.content.replace(/[^\d\.]/g, '');
				if (content == '') {
					return this.$message.error('请输入');
				}
				const response = await this.$api.post('/config_agent_update', {
					shortName: item.shortName,
					content,
					productId: this.query.productId
				});
				if (response && response.code == 200) {
					this.$message.success('保存成功');
					this.getConfig(this.tabKey);
				} else {
					this.$message.error(response.msg);
				}
			},
			async onSubmit(data) {
				const response = await this.$api.post('/config_qrcode_update', {
					qrcode: data.name,
					imgUrl: data.value
				});
				if (response && response.code == 200) {
					this.$message.success('保存成功');
					this.getQrcodes();
				} else {
					this.$message.error(response.msg);
				}
			}
		}
	};
</script>